import {useQuery} from '@tanstack/react-query';
import {
    CarouselItem,
    CompanyInfoProps, ContactPageProps, CoolGridItem,
    fetchCarouselImages, fetchClientCarouselImages,
    fetchCompanyInfo, fetchCoolGridItems, fetchGeographyItem,
    fetchManagementItem, fetchServiceGridItemById, fetchServiceGridItems,
    fetchVacancyItem, GeographyItem
} from '../utils/service/home.service';
import {VacancyData} from "../pages/vacancyPage";
import {ServiceGridItems, ServiceGridProps} from "../organisms/home/serviceGrid";

export const useCompanyInfoData = () => {
    return useQuery<CompanyInfoProps>({
        queryKey: ['companyInfo'],
        queryFn: fetchCompanyInfo,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useContactData = () => {
    return useQuery<ContactPageProps>({
        queryKey: ['contactData'],
        queryFn: async () => {
            const companyInfo = await fetchCompanyInfo();
            const managementItems = await fetchManagementItem();
            return {
                address: companyInfo.address,
                phoneNumber: companyInfo.phoneNumber,
                contactEmail: companyInfo.contactEmail,
                background_page: companyInfo.background_page,
                management: managementItems,
            };
        },
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useVacancyItems = () => {
    return useQuery<VacancyData[]>({
        queryKey: ['vacancyItems'],
        queryFn: fetchVacancyItem,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useCarouselItems = () => {
    return useQuery<CarouselItem[]>({
        queryKey: ['carouselItems'],
        queryFn: fetchCarouselImages,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useClientCarouselItems = () => {
    return useQuery<CarouselItem[]>({
        queryKey: ['clientCarouselItems'],
        queryFn: fetchClientCarouselImages,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useCoolGridItems = () => {
    return useQuery<CoolGridItem[]>({
        queryKey: ['coolGridItems'],
        queryFn: fetchCoolGridItems,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useGeographyItem = () => {
    return useQuery<GeographyItem>({
        queryKey: ['geographyItem'],
        queryFn: fetchGeographyItem,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

export const useServiceGridItems = () => {
    return useQuery<ServiceGridItems[]>({
        queryKey: ['serviceGridItems'],
        queryFn: fetchServiceGridItems,
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};

// Хук для получения элемента по id
export const useServiceGridItemById = (id: string) => {
    return useQuery<ServiceGridItems>({
        queryKey: ['serviceGridItem', id],
        queryFn: () => fetchServiceGridItemById(id),
        enabled: !!id, // Запрос выполняется только если id не пустой
        staleTime: 600000, // 10 минут
        refetchOnWindowFocus: false,
    });
};