import React from 'react';
import styled from 'styled-components';
import NavLink from '../../atoms/header/navlink';

// Интерфейс пропсов для NavList
interface NavListProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

// Фильтрация пропсов с помощью shouldForwardProp
const NavListContainer = styled.ul.withConfig({
    shouldForwardProp: (prop) => !['isMenuOpen'].includes(prop)
})<{ isMenuOpen: boolean }>`
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    flex-direction: row;

    @media (min-width: 1000px) {
        flex-direction: row;
        background-color: transparent;
        position: static;
        width: auto;
        padding-left: 30px;
        display: flex;
    }
`;

const NavListItem = styled.li.withConfig({
    shouldForwardProp: (prop) => prop !== 'isMenuOpen'
})<{ isMenuOpen: boolean }>`
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    @media (max-width: 1000px) {
        display: ${(props) => (props.isMenuOpen ? 'block' : 'none')};
    }
`;

const NavList: React.FC<NavListProps> = ({isMenuOpen, toggleMenu}) => {
    return (
        <NavListContainer isMenuOpen={isMenuOpen}>
            <NavListItem isMenuOpen={isMenuOpen}><NavLink to="/home" label="Главная"/></NavListItem>
            <NavListItem isMenuOpen={isMenuOpen}><NavLink to="/about" label="О компании"/></NavListItem>
            <NavListItem isMenuOpen={isMenuOpen}><NavLink to="/vacancie" label="Вакансии"/></NavListItem>
            <NavListItem isMenuOpen={isMenuOpen}><NavLink to="/contact" label="Контакты"/></NavListItem>
        </NavListContainer>
    );
};

export default NavList;
