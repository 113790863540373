import React, {useEffect, useState, FC} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from './authContext';
import {jwtDecode} from 'jwt-decode';

interface PrivateRouteProps {
    children: JSX.Element;
}

interface Token {
    exp: number;
}

const PrivateRoute: FC<PrivateRouteProps> = ({children}) => {
    const {accessToken, refreshAccessToken} = useAuth();
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const checkToken = async () => {
            if (accessToken) {
                const decodedToken: Token = jwtDecode(accessToken);
                const now = Date.now() / 1000;

                if (decodedToken.exp < now) {
                    await refreshAccessToken();
                }
            }
            setLoading(false);
        };

        checkToken();
    }, [accessToken, refreshAccessToken, location]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!accessToken) {
        return <Navigate to="/" state={{from: location}} replace/>;
    }

    return children;
};


export default PrivateRoute;
