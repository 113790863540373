import React, {Suspense, lazy} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {AuthProvider, useAuth} from './utils/wrapper/authContext';
import TokenRefreshWrapper from './utils/wrapper/tokenRefreshWrapper';
import PrivateRoute from './utils/wrapper/privateRoute';
import Header from './organisms/header';
import Footer from './organisms/footer';
import './App.css';
import LoginPage from "./pages/login.page";

const queryClient = new QueryClient();  // Создаём QueryClient один раз вне компонента

// Динамический импорт страниц для улучшения производительности
const HomePage = lazy(() => import('./pages/home.page'));
const NotFoundPage = lazy(() => import('./pages/notFound.page'));
const SwaggerPage = lazy(() => import('./pages/swaggerPage'));
const CompanyInfoPage = lazy(() => import('./pages/companyInfoPage'));
const ContactPage = lazy(() => import('./pages/contactPage'));
const VacanciesPage = lazy(() => import('./pages/vacancyPage'));
const ServiceDetail = lazy(() => import('./pages/serviceDetailPage'));

const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Router>
                    <TokenRefreshWrapper>
                        <div className="app">
                            <ConditionalLayout>
                                <Suspense fallback={<div>Загрузка...</div>}>
                                    <Routes>
                                        <Route path="/profile" element={<RootRedirect/>}/>
                                        <Route path="/home" element={<HomePage/>}/>
                                        <Route path="/" element={<HomePage/>}/>
                                        <Route path="/about" element={<CompanyInfoPage/>}/>
                                        <Route path="/contact" element={<ContactPage/>}/>
                                        <Route path="/vacancie" element={<VacanciesPage/>}/>
                                        <Route path="/service/:id" element={<ServiceDetail/>}/>
                                        <Route path="*" element={<HandleUnknownPaths/>}/>

                                        <Route path="/swagger" element={<PrivateRoute><SwaggerPage/></PrivateRoute>}/>
                                    </Routes>
                                </Suspense>
                            </ConditionalLayout>
                        </div>
                    </TokenRefreshWrapper>
                </Router>

            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    );
};

const HandleUnknownPaths: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <NotFoundPage/> : <Navigate to="/" replace/>;
};

const ConditionalLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const location = useLocation();
    const hideHeaderAndFooter = location.pathname.startsWith('/login');

    return (
        <>
            {!hideHeaderAndFooter && <Header/>}
            <main>{children}</main>
            {!hideHeaderAndFooter && <Footer/>}
        </>
    );
};

const RootRedirect: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <Navigate to="/profile" replace/> : <LoginPage/>;
};

export default App;
