import React, {createContext, useContext, useState, ReactNode, useCallback, useEffect} from 'react';
import {jwtDecode} from 'jwt-decode';
import axiosInstance from "../service/axiosConfig";

interface AuthContextType {
    accessToken: string | null;
    refreshToken: string | null;
    user: Token | null;
    login: (accessToken: string, refreshToken: string) => void;
    logout: (navigate: (path: string) => void) => void;
    refreshAccessToken: () => Promise<void>;
    updateUser: (updatedUser: Token) => void;
    fetchUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export interface Token {
    exp: number;
    group: string;
    first_name: string;
    last_name: string;
    username: string;
    avatar: string;
}

export function UpdateLocalStorage(decodedToken: Token, newAccessToken?: string, newRefreshToken?: string) {
    if (newAccessToken) {
        localStorage.setItem('accessToken', newAccessToken);
    }
    if (newRefreshToken) {
        localStorage.setItem('refreshToken', newRefreshToken);
    }

}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'));
    const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem('refreshToken'));
    const [user, setUser] = useState<Token | null>(() => {
        const token = localStorage.getItem('accessToken');
        return token ? jwtDecode<Token>(token) : null;
    });

    const login = (newAccessToken: string, newRefreshToken: string) => {
        setAccessToken(null);
        setRefreshToken(null);
        setUser(null);
        // console.log("Login: Clearing user state");
        setTimeout(() => {
            setAccessToken(newAccessToken);
            setRefreshToken(newRefreshToken);
            const decodedToken = jwtDecode<Token>(newAccessToken);
            setUser(decodedToken);
            // console.log("Login: Setting user to", decodedToken);
            UpdateLocalStorage(decodedToken, newAccessToken, newRefreshToken);
        }, 0);
    };


    const logout = (navigate: (path: string) => void) => {
        setAccessToken(null);
        setRefreshToken(null);
        setUser(null);
        localStorage.clear()
        navigate('/');
    };

    const refreshAccessToken = useCallback(async () => {
        if (!refreshToken) {
            logout(() => window.location.href = '/');
            return;
        }

        try {
            const response = await axiosInstance.post('/api/token/refresh/', {
                refresh: refreshToken,
            });

            if (response.status !== 200) {
                throw new Error('Failed to refresh token');
            }

            const data = response.data;
            const newAccessToken = data.access;
            const decodedToken = jwtDecode<Token>(newAccessToken);

            setAccessToken(newAccessToken);
            setUser(decodedToken);
            UpdateLocalStorage(decodedToken, newAccessToken, refreshToken);
            console.log('Refresh access token successfully');
        } catch (error) {
            console.error('Failed to refresh access token:', error);
            logout(() => window.location.href = '/');
        }
    }, [refreshToken, logout]);


    const updateUser = (updatedUser: Token) => {
        setUser(updatedUser);
        UpdateLocalStorage(updatedUser, accessToken ?? '', refreshToken ?? '');
    };

    const fetchUser = useCallback(async () => {
        if (!accessToken) {
            console.log('No access token available, skipping user data fetch.');
            return;
        }

        try {
            const response = await axiosInstance.get('/api/profile/');
            const data = response.data[0];

            let avatarSrc = data.avatar || '';
            if (!avatarSrc.startsWith('/media/')) {
                avatarSrc = `/media/${avatarSrc}`;
            }

            const updatedUser: Token = {
                exp: data.exp,
                group: data.groups,
                first_name: data.user_first_name,
                last_name: data.user_last_name,
                username: data.username,
                avatar: avatarSrc || '',
            };

            setUser(updatedUser);
            UpdateLocalStorage(updatedUser, accessToken ?? '', refreshToken ?? '');
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }, [accessToken, refreshToken]);


    useEffect(() => {
        if (accessToken) {
            fetchUser();
            const decodedToken = jwtDecode<Token>(accessToken);
            setUser(decodedToken);
        }
    }, [accessToken, fetchUser]);

    return (
        <AuthContext.Provider
            value={{accessToken, refreshToken, user, login, logout, refreshAccessToken, updateUser, fetchUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth error');
    }
    return context;
};
