import React, {useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {useLocation} from 'react-router-dom';
import ContactForm from './home/contactUsForm';
import {useCompanyInfoData} from "../hooks/useHomePageData";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

interface AnimatedContainerProps {
    $showForm: boolean;
}

const AnimatedContainer = styled.div<AnimatedContainerProps>`
    animation: ${props => (props.$showForm ? fadeIn : fadeOut)} 0.3s ease;
`;

const FooterContainer = styled.footer<{ $isHomePage: boolean }>`
    width: 100%;
    background-color: #080d21;
    color: #ffffff;
    padding: ${({$isHomePage}) => ($isHomePage ? '40px 20px' : '10px 20px')};
    display: flex;
    justify-content: center; /* Центрируем дочерний контейнер */
    box-sizing: border-box;
    position: relative;
    transition: height 0.3s ease;
    height: ${({$isHomePage}) => ($isHomePage ? '400px' : 'unset')};

    @media (max-width: 768px) {
        height: ${({$isHomePage}) => ($isHomePage ? 'unset' : '50px')};
    }
`;

const FooterContent = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ContactBlock = styled.div`
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
        flex: 1;
        margin-bottom: 0;
    }
`;

const ContactTitle = styled.h3`
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #eb7515;
`;

const ContactDetails = styled.div`
    //margin-bottom: 20px;
`;

const ContactItem = styled.p`
    //margin: 5px 0;
`;

const MapBlock = styled.div`
    flex: 0 0 65%;
    margin-left: 20px;
    margin-bottom: 20px;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
        flex: 1;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: unset;
    }
`;

const Copyright = styled.div<{ $isHomePage: boolean }>`
    width: 100%;
    text-align: center;
    margin-top: ${({$isHomePage}) => ($isHomePage ? 0 : 'unset')};
    font-size: 14px;
    color: #888888;

    @media (max-width: 768px) {
        margin-top: ${({$isHomePage}) => ($isHomePage ? '30px' : '10px')};
    }
`;

const ToggleButton = styled.button`
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #eb7515;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    height: 42px;
    width: 200px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c9691a;
    }

    @media (max-width: 768px) {
        padding: 8px 12px;
    }
`;
const StyledIframe = styled.iframe`
    
    height: 300px;
    width: 100%;
    min-width: 300px;
    border-radius: 10px;
    border: none;
`;

const Footer: React.FC = () => {
    const {data: companyInfo, isLoading: companyLoading} = useCompanyInfoData();
    const [showForm, setShowForm] = useState(false);
    const location = useLocation();
    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    const toggleView = () => {
        setShowForm(!showForm);
    };

    const mapLink = 'https://yandex.ru/map-widget/v1/?ll=76.097168%2C61.024636&mode=search&oid=86903141004&ol=biz&z=17.64';

    return (
        <FooterContainer $isHomePage={isHomePage}>
            <FooterContent>
                {isHomePage && (
                    <>
                        <ContactBlock>
                            <ContactTitle>Контактная информация</ContactTitle>
                            <ContactDetails>
                                <ContactItem>Адрес: {companyInfo?.address}</ContactItem>
                                <ContactItem>Телефон: {companyInfo?.phoneNumber}</ContactItem>
                                <ContactItem>
                                    Email: <a href={`mailto:${companyInfo?.contactEmail}`}
                                              style={{color: '#eb7515'}}>{companyInfo?.contactEmail}</a>
                                </ContactItem>
                            </ContactDetails>
                            <ToggleButton onClick={toggleView}>
                                {showForm ? 'Показать карту' : 'Связаться с нами'}
                            </ToggleButton>
                        </ContactBlock>

                        <MapBlock>
                            <AnimatedContainer $showForm={showForm}>
                                {showForm ? <ContactForm height={'280px'}
                                                         textAreaHeight={'50px'}/> :
                                    // <MapComponent mapUrl={companyInfo?.mapUrl} height={'300px'}/>
                                    <StyledIframe
                                        src={mapLink}
                                        title="Компания на карте"
                                        allowFullScreen
                                        aria-hidden="false"
                                        tabIndex={0}
                                    />
                                }
                            </AnimatedContainer>
                        </MapBlock>
                    </>
                )}

                <Copyright $isHomePage={isHomePage}>
                    &copy; {new Date().getFullYear()} ООО СК ТПС. Все права защищены.
                </Copyright>
            </FooterContent>
        </FooterContainer>
    );
};

export default Footer;
