import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";


const StyledLink = styled(Link)`
    position: relative;
    text-decoration: none;
    color: #ece7e0;
    font-size: 1.3rem;
    overflow: hidden;
    transition: color 0.3s ease-in-out;
    font-weight: bold;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease-in-out;
    }

    &:hover::before {
        transform: translateY(0);
    }

    &:hover::after {
        transform: scaleX(1);
    }

    &:hover {
        color: #333;
    }

    @media (max-width: 768px) {
        margin: 10px 0;
        text-align: center;
        width: 100%;
    }
`;

interface NavLinkProps {
    to: string;
    label: string;
}

const NavLink: React.FC<NavLinkProps> = ({to, label}) => {
    return (
        <StyledLink to={to}>
            {label}
        </StyledLink>
    );
};

export default NavLink;
