import React, {useState} from 'react';
import styled from 'styled-components';
import {loginUser} from "../../utils/service/login.service";
import {sendEmail} from "../../utils/service/home.service";

// Стили для формы
const FormContainer = styled.div<{ $height: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${({$height}) => $height ? $height : 'unset'};
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Убираем прокрутку для избежания растягивания */
    animation: fadeIn 0.3s ease;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media (max-width: 768px) {
        height: unset;
    }
`;

const FormTitle = styled.h3`
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #eb7515;
    text-align: center;
    @media (max-width: 768px) {
        //margin: unset;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    outline: none;

    &:focus {
        box-shadow: 0 0 5px #eb7515;
    }
`;

const TextArea = styled.textarea <{$textAreaHeight:string}>`
    padding: 5px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    resize: vertical;
    height: ${({$textAreaHeight}) => $textAreaHeight ? $textAreaHeight : '50px'};

    &:focus {
        box-shadow: 0 0 5px #eb7515;
    }
`;

const SubmitButton = styled.button`
    padding: 12px;
    background-color: #eb7515;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c9691a;
    }
`;

const SuccessMessage = styled.p`
    color: #4caf50;
    font-size: 18px;
    text-align: center;
`;

interface ContactFormInterface {
    height: string;
    textAreaHeight: string;
}

const ContactForm: React.FC<ContactFormInterface> = ({height, textAreaHeight}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Обработка отправки формы
        console.log({name, email, message});
        await sendEmail({name, message, email});
        setIsSubmitted(true);
    };


    return (
        <FormContainer $height={height}>
            <FormTitle>Свяжитесь с нами</FormTitle>
            {isSubmitted ? (
                <SuccessMessage>Спасибо! Ваше сообщение отправлено.</SuccessMessage>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        placeholder="Ваше имя"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <Input
                        type="email"
                        placeholder="Ваш Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextArea $textAreaHeight={textAreaHeight}
                        placeholder="Ваше сообщение"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                    <SubmitButton type="submit">Отправить сообщение</SubmitButton>
                </Form>
            )}
        </FormContainer>
    );
};

export default ContactForm;
